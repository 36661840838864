import type {
    Attachment,
    AttachmentDimensions,
    AttachmentUploadFields,
    StoredObject,
    StoredObjectResourceTypes,
    Transaction,
    TransactionSubType,
    TransactionType,
} from '@nexdynamic/squeegee-common';
import { Linker } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../ApplicationState';
import { Data } from '../Data/Data';
import type { IImageData } from '../IImageData';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { JobService } from '../Jobs/JobService';
import { Logger } from '../Logger';
import { Api } from '../Server/Api';
import { Utilities } from '../Utilities';
import type { TImageAttachmentQuality } from './TImageAttachmentQuality';
import type { TImageAttachmentResolution } from './TImageAttachmentResolution';

export class AttachmentService {
    static getPublicUrl(attachment: Attachment): string {
        return `${Api.currentHostAndScheme}/v/${attachment._id}`;
    }
    /**
     * The maxiumun allowed size of one file
     * @static
     */
    public static MAX_FILE_SIZE = 52428800;
    /**
     * Fetch all attachments for the passed parentId
     * @static
     * @param {string} parentId The id of the parent object that the attachments are for ie: customerId, JobId
     * @return {*}  {Array<Attachment>}
     */
    public static fetchAll(parentId: string): Array<Attachment> {
        const linker = AttachmentService.getLinkerForParent(parentId);
        const attachments = [];
        if (linker) {
            const links = Linker.getLinks(linker);

            //TODO Check if batch get exists if not create it
            for (const link of links) {
                const attachment = Data.get<Attachment>(link.targetId);
                if (attachment) attachments.push(attachment);
            }
        }

        return attachments;
    }

    /**
     * Upload a file and create an attachment
     * If parentId is passed then it will automaitclly create a link between the attachment and the parent
     * @static
     * @param {File} file The file to upload
     * @param {string} fileId The file id to use when storing the file remotely
     * @param {string} name A user friendly name to store on the attachment
     * @param {number} size The size of the file in bytes
     * @param {string} mimeType The file type of the passed file
     * @param {string} [thumbnail] Optional thumbnail to store for image files
     * @param {string} [parentId] The id of the resouce you want to create an attachment for
     * @param {StoredObjectResourceTypes} [parentType]
     * @param {('' | TransactionType | TransactionSubType)} [parentSubType]
     * @param {(event: ProgressEvent) => void} [progressHandler]
     * @param {{ cancel: (msg: string) => void }} [canceler]
     * @return {*}  {(Promise<Attachment | undefined>)}
     * @memberof AttachmentService
     */
    public static async upload(
        file: File,
        fileId: string,
        thumbnail?: string,
        dimensions?: AttachmentDimensions,
        parentId?: string,
        parentType?: StoredObjectResourceTypes,
        parentSubType?: '' | TransactionType | TransactionSubType,
        isPublic?: boolean,
        progressHandler?: (event: ProgressEvent) => void,
        canceler?: { cancel: (msg: string) => void }
    ): Promise<void> {
        if (file && file.size && file.size > AttachmentService.MAX_FILE_SIZE) throw new Error('File size too large!');

        const attachmentName = file.name ? file.name.trim() : undefined;
        let mimeType = file.type;
        if (!file.type) {
            // see https://www.rfc-editor.org/rfc/rfc2046.txt The "octet-stream" subtype is used to indicate that a body contains arbitrary binary data.
            mimeType = 'application/octet-stream';
        }

        if (file && attachmentName && file.size && fileId && mimeType) {
            if (parentId && !parentType) throw 'Invalid params parent id was passed but parent type was not set';

            const params = {
                fileId,
                name: attachmentName,
                thumbnail,
                dimensions: dimensions ? JSON.stringify(dimensions) : undefined,
                fileSize: file.size,
                mimeType,
                isPublic,
            };
            await Api.postFile<AttachmentUploadFields, void>(
                null,
                '/api/attachments',
                file,
                parentId ? Object.assign(params, { parentId, parentType, parentSubType }) : params,
                undefined,
                false,
                progressHandler,
                canceler
            );
        } else {
            Logger.error('Upload was called but invalid params were passed');
            throw 'Invalid file upload';
        }
    }

    public static getAll(): Array<Attachment> {
        return Data.all<Attachment>('attachments').slice();
    }

    /**
     * Unlinks the passed attachment from the passed linker and syncs changes to server
     * @static
     * @param {string} parentId The id of the parent to remove attachment from
     * @param {Attachment} attachment The attachment to update
     */
    public static async remove(parentId: string, attachment: Attachment) {
        try {
            const linker = Data.get<Linker>(Linker.getId(parentId, 'attachment'));

            if (linker && linker.resourceType === 'linkers') {
                // Unlink this attachment from the linker
                Linker.unlink(linker, attachment._id);
                // Update linker
                Data.put(linker);
            } else {
                // This should never happen
                throw 'Unexpected: attachment removed was called but no linker existed for the passed attachment';
            }
        } catch (error) {
            Logger.error('Unable to remove attachment', error);
            throw error;
        }
    }
    /**
     * Takes the passed attachment and updated the locally stored object and syncs to server
     * @static
     * @param {Attachment} attachment
     */
    public static async update(attachment: Attachment) {
        try {
            await Data.put(attachment);
        } catch (error) {
            Logger.error(`Unable to update attachment ${attachment._id}`, error);
        }
    }

    /**
     * Gets a linker for a given parent id requires a parent object
     * @static
     * @param {string} parentId
     * @return {*}
     */
    public static getLinkerForParent(parentId: string) {
        return Data.get<Linker>(Linker.getId(parentId, 'attachment'));
    }

    public static getLinkersForAttachment(attachmentId: string) {
        return Data.all<Linker>('linkers', linker => Linker.hasLink(linker, attachmentId)).slice();
    }

    /**
     * Returns the attachments file src url
     * @static
     * @param {string} attachmentId
     * @return {*}  {string}
     */
    public static async getLinkUrl(attachment: Attachment): Promise<string | undefined> {
        const oneTimeUrl = await Api.get<string>(null, `/api/attachments/get-once/${attachment._id}`, undefined, undefined, false);
        const attachmentParams = attachment.dimensions ? '' : `?filename=${attachment.name}`;
        return oneTimeUrl && oneTimeUrl.data && `${Api.apiEndpoint}${oneTimeUrl.data}${attachmentParams}`;
    }
    /**
     * Determines wether the passed attachment id is linked to any other resoucres
     * @static
     * @param {string} attachmentId
     * @return {*}  {boolean}
     */
    public static isAttached(attachmentId: string): boolean {
        // Get all linkers and find ones with this attachment id
        const query = (linker: Linker) => Linker.hasLink(linker, attachmentId);

        return Data.all('linkers', query).length > 0;
    }

    /**
     * Deletes attachment and unlinks from all linkers which once synced to server will remove the remote file stored in cloud storage
     * @static
     * @param {Attachment} attachment
     */
    public static async delete(attachment: Attachment) {
        try {
            // get all linkers for this attachment

            const linkers = AttachmentService.getLinkersForAttachment(attachment._id);
            const linkersToUpdate: Array<Linker> = [];
            for (const linker of linkers) {
                Linker.unlink(linker, attachment._id);
                linkersToUpdate.push(linker);
            }

            await Data.put(linkersToUpdate);
            await Data.delete(attachment);
        } catch (error) {
            Logger.error(`Unable to delete attachment ${attachment ? attachment._id : 'Unknown attachment id'}`, error);
            throw error;
        }
    }

    public static async replace(
        file: File,
        fileId: string,
        thumbnail?: string,
        dimensions?: { x: number; y: number },
        isPublic?: boolean,
        progressHandler?: (event: ProgressEvent) => void,
        canceler?: { cancel: (msg: string) => void }
    ): Promise<void> {
        try {
            if (file && file.size && file.size > AttachmentService.MAX_FILE_SIZE) throw new Error('File size to large!');

            const attachmentName = file.name ? file.name.trim() : undefined;
            let mimeType = file.type;
            if (!file.type) {
                // see https://www.rfc-editor.org/rfc/rfc2046.txt The "octet-stream" subtype is used to indicate that a body contains arbitrary binary data.
                mimeType = 'application/octet-stream';
            }

            if (file && attachmentName && file.size && fileId && mimeType) {
                await Api.postFile(null, '/api/attachments/update-file', file, { fileId }, undefined, false, progressHandler, canceler);
                // Update the local attachment with new file metadata
                const attachment = Data.get<Attachment>(fileId);

                if (attachment) {
                    attachment.dimensions = dimensions;
                    attachment.name = attachmentName;
                    attachment.mimeType = mimeType;
                    attachment.size = file.size;
                    attachment.thumbnail = thumbnail || '';
                    attachment.isPublic = isPublic;
                    AttachmentService.update(attachment);
                } else {
                    Logger.error('Attachment file was replaced remotely but, was unable to find the local attachment to update');
                }
            } else {
                Logger.error('Replace was called but invalid params were passed');
                throw 'Invalid file params';
            }
        } catch (error) {
            Logger.error('Unable to replace attachment', error);
        }
    }

    /**
     * Returns all items that the passed attachmentId is linked to
     * @static
     * @param {string} attachmentId
     * @return {*}  {Array<StoredObject>}
     * @memberof AttachmentService
     */
    public static getAttachmentParents(attachmentId: string): Array<StoredObject> {
        const parents = [];

        const linkers = AttachmentService.getLinkersForAttachment(attachmentId);

        for (const linker of linkers) {
            const parentId = Linker.getParentId(linker);
            let parent = Data.get(parentId);

            // If parent does not exists then check for transient parents
            if (!parent) {
                switch (linker.parentType) {
                    case 'joboccurrences':
                        try {
                            parent = JobOccurrenceService.getOrCreateJobOccurence(parentId);
                        } catch (error) {
                            Logger.error('Unable to get parent for joboccurrence linker');
                        }
                        break;
                    // WTF Jobs exist on customers so we need to fetch them
                    case 'jobs':
                        try {
                            parent = JobService.getJob(undefined, parentId);
                        } catch (error) {
                            Logger.error('Unable to get parent for job linker');
                        }
                        break;
                }
            }

            if (parent) parents.push(parent);
            else Logger.error(`Error fetching attachment parent: ${parentId} does not exist! this should not happen`);
        }

        return parents;
    }
    /**
     * Links the passed attachment to the parent
     * @static
     * @param {StoredObject} parent
     * @param {Attachment} attachment
     */
    public static async linkAttachment(attachment: Attachment, parent: StoredObject) {
        let linker = AttachmentService.getLinkerForParent(parent._id);
        // If there isn't a linker for this parent then create a new one
        if (!linker)
            linker = new Linker(
                parent._id,
                parent.resourceType as StoredObjectResourceTypes,
                AttachmentService.getParentSubType(parent),
                'attachment'
            );

        Linker.link(linker, attachment);

        await Data.put(linker);
    }

    /**
     * Returns a parent subtype for the passed parent
     * @static
     * @param {StoredObject} parent
     * @return {*}
     */
    public static getParentSubType(parent: StoredObject) {
        if ((parent as Transaction).transactionType) return (parent as Transaction).transactionType;

        return '';
    }

    /**
     * Gets a resourceType for the passed parent used to fix issues with some stored objects
     * @static
     * @param {StoredObject} parent
     * @return {*}
     */
    public static getParentType(parent: StoredObject): StoredObjectResourceTypes {
        if (parent.resourceType) return parent.resourceType;
        else {
            // WTF Jobs don't have resource types but they can be attached to so we need a resourceType remove when jobs are out of customers
            return 'jobs';
        }
    }
    /**
     * Returns a the amount of storage attachments are using in bytes
     * @static
     * @return {*}  {number}
     */
    public static storageSpacedUsed(): number {
        const attachments = Data.all<Attachment>('attachments');

        return attachments.reduce((total, b) => {
            return total + b.size;
        }, 0);
    }
    /**
     *
     * Generates a encoded base64 string for the passed file if the mimetype is a image
     * @static
     * @param {File} file
     * @return {*}
     * @memberof AttachmentService
     */
    public static async getImageData(file: File, createThumbnail = false): Promise<IImageData | undefined> {
        if (file.type && file.type.includes('image') && !file.type.includes('heif')) {
            try {
                const imageAttachmentResolution = ApplicationState.getSetting<TImageAttachmentResolution>(
                    'global.image-attachment-resolution',
                    'resolution1440p'
                );
                const imageAttachmentQuality = ApplicationState.getSetting<TImageAttachmentQuality>(
                    'global.image-attachment-resolution',
                    'qualityHigh'
                );
                return await Utilities.generateImageData(
                    file,
                    [120, 120],
                    imageAttachmentResolution,
                    imageAttachmentQuality,
                    createThumbnail
                );
            } catch (error) {
                Logger.error('Unable to generate a thumbnail', error);
            }
        }
    }
}

import type { AuditSubject, StoredObject } from '@nexdynamic/squeegee-common';
import { AuditEvent } from '@nexdynamic/squeegee-common';
import type { Dexie, Table } from 'dexie';
import dexie from 'dexie';
import { ApplicationState } from './ApplicationState';
import { Logger } from './Logger';
import { Api } from './Server/Api';

export class AuditManager {
    private static _initialising: Promise<Dexie & { events: Table<AuditEvent, string> } | void>;
    private static _auditDb: Dexie & { events: Table<AuditEvent, string> };
    public static async initialise() {
        if (AuditManager._initialising) return AuditManager._initialising;

        AuditManager._initialising = new Promise<void>(async (resolve, reject) => {
            try {
                AuditManager._auditDb = <dexie & { events: dexie.Table<AuditEvent, string> }>new dexie('squegee_audit', { chromeTransactionDurability: 'relaxed' });
                AuditManager._auditDb.version(1).stores({ events: '&_id' });
                await AuditManager._auditDb.open();
                resolve();
            } catch (error) {
                Logger.error('Failed to initialise AuditManager', error);
                reject()
            }
        });

        window.sq.AuditManager = AuditManager;

        return await AuditManager._initialising;
    }

    public static async recordAuditEvent(action: string, items: Array<StoredObject>) {
        if (!items?.length) return;

        const auditData = await AuditManager.initialise();
        if (!auditData) return;

        const subject: AuditSubject = [items[0]._id, items[0].resourceType];
        const relatedSubjects: Array<AuditSubject> | undefined = items.length > 1 ? items.slice(1).map(x => [x._id, x.resourceType]) : undefined;
        const auditEvent = new AuditEvent(subject, action, ApplicationState.version, ApplicationState.deviceDescription, relatedSubjects,);

        auditEvent.ownerEmail = ApplicationState.dataEmail;
        auditEvent.createdOnDevice = ApplicationState.deviceId;

        await auditData.events.add(auditEvent);

        AuditManager.initAuditEventSender();
    }

    private static _auditEventSenderTimeout: any;
    public static async initAuditEventSender() {
        const auditData = await AuditManager.initialise();
        if (!auditData) return;

        clearTimeout(AuditManager._auditEventSenderTimeout);

        const sendAuditEvents = async () => {
            try {
                if (!Api.isConnected || window.location.hostname === 'localhost') return;
                const count = 0;
                await auditData.events.each(entry => {
                    Api.V2.submitAuditEvent(entry);
                    auditData.events.delete(entry._id);
                });

                if (count) Logger.info(`Logged ${count} audit events with Squeegee.`);
            } catch (error) {
                Logger.info('Error sending audit events', error);
            } finally {
                AuditManager._auditEventSenderTimeout = setTimeout(sendAuditEvents, 15000);
            }
        };

        sendAuditEvents();
    }
}
